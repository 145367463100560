// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-composite-blog-author-and-tag-details-page-tsx": () => import("./../../../src/components/composite/blog/AuthorAndTagDetailsPage.tsx" /* webpackChunkName: "component---src-components-composite-blog-author-and-tag-details-page-tsx" */),
  "component---src-components-composite-blog-post-details-page-tsx": () => import("./../../../src/components/composite/blog/PostDetailsPage.tsx" /* webpackChunkName: "component---src-components-composite-blog-post-details-page-tsx" */),
  "component---src-components-composite-casestudies-post-details-tsx": () => import("./../../../src/components/composite/casestudies/PostDetails.tsx" /* webpackChunkName: "component---src-components-composite-casestudies-post-details-tsx" */),
  "component---src-components-composite-finnoscore-comparison-embedded-compare-page-tsx": () => import("./../../../src/components/composite/finnoscore-comparison/EmbeddedComparePage.tsx" /* webpackChunkName: "component---src-components-composite-finnoscore-comparison-embedded-compare-page-tsx" */),
  "component---src-components-composite-finnoscore-comparison-index-tsx": () => import("./../../../src/components/composite/finnoscore-comparison/index.tsx" /* webpackChunkName: "component---src-components-composite-finnoscore-comparison-index-tsx" */),
  "component---src-components-composite-finnoscore-list-embedded-list-page-tsx": () => import("./../../../src/components/composite/finnoscore-list/EmbeddedListPage.tsx" /* webpackChunkName: "component---src-components-composite-finnoscore-list-embedded-list-page-tsx" */),
  "component---src-components-composite-finnoscore-list-index-tsx": () => import("./../../../src/components/composite/finnoscore-list/index.tsx" /* webpackChunkName: "component---src-components-composite-finnoscore-list-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-banks-compare-preview-tsx": () => import("./../../../src/pages/banks/compare-preview.tsx" /* webpackChunkName: "component---src-pages-banks-compare-preview-tsx" */),
  "component---src-pages-banks-middleeast-compare-preview-tsx": () => import("./../../../src/pages/banks-middleeast/compare-preview.tsx" /* webpackChunkName: "component---src-pages-banks-middleeast-compare-preview-tsx" */),
  "component---src-pages-banks-middleeast-preview-tsx": () => import("./../../../src/pages/banks-middleeast-preview.tsx" /* webpackChunkName: "component---src-pages-banks-middleeast-preview-tsx" */),
  "component---src-pages-banks-preview-tsx": () => import("./../../../src/pages/banks-preview.tsx" /* webpackChunkName: "component---src-pages-banks-preview-tsx" */),
  "component---src-pages-blog-preview-tsx": () => import("./../../../src/pages/blog-preview.tsx" /* webpackChunkName: "component---src-pages-blog-preview-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-case-study-preview-tsx": () => import("./../../../src/pages/case-study-preview.tsx" /* webpackChunkName: "component---src-pages-case-study-preview-tsx" */),
  "component---src-pages-chart-generator-tsx": () => import("./../../../src/pages/chart-generator.tsx" /* webpackChunkName: "component---src-pages-chart-generator-tsx" */),
  "component---src-pages-charts-comparison-chart-tsx": () => import("./../../../src/pages/charts/comparison-chart.tsx" /* webpackChunkName: "component---src-pages-charts-comparison-chart-tsx" */),
  "component---src-pages-charts-comparison-tsx": () => import("./../../../src/pages/charts/comparison-.tsx" /* webpackChunkName: "component---src-pages-charts-comparison-tsx" */),
  "component---src-pages-charts-finnoscore-uploader-tsx": () => import("./../../../src/pages/charts/finnoscore-uploader.tsx" /* webpackChunkName: "component---src-pages-charts-finnoscore-uploader-tsx" */),
  "component---src-pages-charts-forgotpassword-tsx": () => import("./../../../src/pages/charts/forgotpassword.tsx" /* webpackChunkName: "component---src-pages-charts-forgotpassword-tsx" */),
  "component---src-pages-charts-guide-tsx": () => import("./../../../src/pages/charts/guide.tsx" /* webpackChunkName: "component---src-pages-charts-guide-tsx" */),
  "component---src-pages-charts-history-ranking-chart-tsx": () => import("./../../../src/pages/charts/history-ranking-chart.tsx" /* webpackChunkName: "component---src-pages-charts-history-ranking-chart-tsx" */),
  "component---src-pages-charts-index-tsx": () => import("./../../../src/pages/charts/index.tsx" /* webpackChunkName: "component---src-pages-charts-index-tsx" */),
  "component---src-pages-charts-international-chart-tsx": () => import("./../../../src/pages/charts/international-chart.tsx" /* webpackChunkName: "component---src-pages-charts-international-chart-tsx" */),
  "component---src-pages-charts-login-tsx": () => import("./../../../src/pages/charts/login.tsx" /* webpackChunkName: "component---src-pages-charts-login-tsx" */),
  "component---src-pages-charts-specific-chart-tsx": () => import("./../../../src/pages/charts/specific-chart.tsx" /* webpackChunkName: "component---src-pages-charts-specific-chart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-data-privacy-quiz-tsx": () => import("./../../../src/pages/data-privacy-quiz.tsx" /* webpackChunkName: "component---src-pages-data-privacy-quiz-tsx" */),
  "component---src-pages-data-privacy-tsx": () => import("./../../../src/pages/data-privacy.tsx" /* webpackChunkName: "component---src-pages-data-privacy-tsx" */),
  "component---src-pages-finnoscore-banks-middleeast-tsx": () => import("./../../../src/pages/finnoscore/banks-middleeast.tsx" /* webpackChunkName: "component---src-pages-finnoscore-banks-middleeast-tsx" */),
  "component---src-pages-finnoscore-digital-insurance-experience-studie-tsx": () => import("./../../../src/pages/finnoscore/digital-insurance-experience-studie.tsx" /* webpackChunkName: "component---src-pages-finnoscore-digital-insurance-experience-studie-tsx" */),
  "component---src-pages-finnoscore-insurance-tsx": () => import("./../../../src/pages/finnoscore/insurance.tsx" /* webpackChunkName: "component---src-pages-finnoscore-insurance-tsx" */),
  "component---src-pages-finnoscore-privatebanks-tsx": () => import("./../../../src/pages/finnoscore/privatebanks.tsx" /* webpackChunkName: "component---src-pages-finnoscore-privatebanks-tsx" */),
  "component---src-pages-finnoscore-quiz-tsx": () => import("./../../../src/pages/finnoscore/quiz.tsx" /* webpackChunkName: "component---src-pages-finnoscore-quiz-tsx" */),
  "component---src-pages-finnoscore-tsx": () => import("./../../../src/pages/finnoscore.tsx" /* webpackChunkName: "component---src-pages-finnoscore-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insurances-compare-preview-tsx": () => import("./../../../src/pages/insurances/compare-preview.tsx" /* webpackChunkName: "component---src-pages-insurances-compare-preview-tsx" */),
  "component---src-pages-insurances-preview-tsx": () => import("./../../../src/pages/insurances-preview.tsx" /* webpackChunkName: "component---src-pages-insurances-preview-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-nearshoring-tsx": () => import("./../../../src/pages/nearshoring.tsx" /* webpackChunkName: "component---src-pages-nearshoring-tsx" */),
  "component---src-pages-privatebanks-compare-preview-tsx": () => import("./../../../src/pages/privatebanks/compare-preview.tsx" /* webpackChunkName: "component---src-pages-privatebanks-compare-preview-tsx" */),
  "component---src-pages-privatebanks-preview-tsx": () => import("./../../../src/pages/privatebanks-preview.tsx" /* webpackChunkName: "component---src-pages-privatebanks-preview-tsx" */),
  "component---src-pages-rapid-tsx": () => import("./../../../src/pages/rapid.tsx" /* webpackChunkName: "component---src-pages-rapid-tsx" */),
  "component---src-pages-telcos-compare-preview-tsx": () => import("./../../../src/pages/telcos/compare-preview.tsx" /* webpackChunkName: "component---src-pages-telcos-compare-preview-tsx" */),
  "component---src-pages-telcos-preview-tsx": () => import("./../../../src/pages/telcos-preview.tsx" /* webpackChunkName: "component---src-pages-telcos-preview-tsx" */),
  "component---src-pages-todo-download-preview-tsx": () => import("./../../../src/pages/todo-download-preview.tsx" /* webpackChunkName: "component---src-pages-todo-download-preview-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */)
}

